var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "apartment-card": true, "apartment-card--full": _vm.full } },
    [
      _c("div", { staticClass: "apartment-card__body" }, [
        _c("div", { staticClass: "apartment-card__floor" }, [
          _c("img", {
            staticClass: "apartment-card__floor-image",
            attrs: { src: _vm.planImage },
          }),
        ]),
        _c(
          "div",
          { staticClass: "apartment-card__container apart-container" },
          [
            _c("div", { staticClass: "apart-container__top" }, [
              _c("div", { staticClass: "apart-container__name" }, [
                _vm._v(" " + _vm._s(_vm.apartmentName) + " "),
              ]),
              _c("div", { staticClass: "apart-container__location" }, [
                _vm._v(
                  " Секция " +
                    _vm._s(_vm.korpus) +
                    ", этаж " +
                    _vm._s(_vm.floor) +
                    " "
                ),
              ]),
              _vm.status === "booked"
                ? _c("div", { staticClass: "apart-container__booked" }, [
                    _c("img", {
                      staticClass: "apart-container__booked-img",
                      attrs: {
                        src: require("@/assets/images/icons/booked.png"),
                      },
                    }),
                    _c("div", { staticClass: "apart-container__booked-text" }, [
                      _vm._v("Бронь"),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "apart-container__number" }, [
                _vm._v("№" + _vm._s(_vm.number)),
              ]),
            ]),
            _c("div", { staticClass: "apart-container__bottom" }, [
              _c("div", { staticClass: "apart-container__cost" }, [
                _c("div", { staticClass: "apart-container__cost-now_min" }, [
                  _vm.newCost
                    ? _c("div", { staticClass: "apart-container__cost-old" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(parseInt(_vm.cost).toLocaleString("ru")) +
                              " ₽"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "apart-container__cost-current" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          parseInt(
                            _vm.newCost ? _vm.newCost : _vm.cost
                          ).toLocaleString("ru")
                        ) +
                        " ₽ "
                    ),
                  ]),
                ]),
                _vm.status !== "available_agent"
                  ? _c("div", { staticClass: "apart-container__cost-now" }, [
                      _vm._v(
                        " или от " +
                          _vm._s(parseInt(_vm.price).toLocaleString("ru")) +
                          " ₽/мес "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  class: [
                    "apart-container__type",
                    { "apart-container__type--no-text": _vm.hideTagText },
                  ],
                },
                [
                  _vm.newCost && _vm.properties.give_meters
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "apart-container__type-item apart-container__type-item--promo",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "apart-container__type-tag" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "apart-container__type-icon",
                                  attrs: {
                                    width: "16",
                                    height: "16",
                                    viewBox: "0 0 16 16",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M3.92334 3.08537C3.46054 3.08537 3.08537 3.46054 3.08537 3.92334C3.08537 4.38615 3.46054 4.76132 3.92334 4.76132C4.38615 4.76132 4.76132 4.38615 4.76132 3.92334C4.76132 3.46054 4.38615 3.08537 3.92334 3.08537ZM1.5 3.92334C1.5 2.58497 2.58497 1.5 3.92334 1.5C5.26172 1.5 6.34669 2.58497 6.34669 3.92334C6.34669 5.26172 5.26172 6.34669 3.92334 6.34669C2.58497 6.34669 1.5 5.26172 1.5 3.92334ZM14.2678 1.73217C14.5774 2.04173 14.5774 2.54363 14.2678 2.85319L2.85319 14.2678C2.54363 14.5774 2.04173 14.5774 1.73217 14.2678C1.42261 13.9583 1.42261 13.4564 1.73217 13.1468L13.1468 1.73217C13.4564 1.42261 13.9583 1.42261 14.2678 1.73217ZM12.0767 11.2387C11.6138 11.2387 11.2387 11.6138 11.2387 12.0767C11.2387 12.5395 11.6138 12.9146 12.0767 12.9146C12.5395 12.9146 12.9146 12.5395 12.9146 12.0767C12.9146 11.6138 12.5395 11.2387 12.0767 11.2387ZM9.65331 12.0767C9.65331 10.7383 10.7383 9.65331 12.0767 9.65331C13.4151 9.65331 14.5 10.7383 14.5 12.0767C14.5 13.4151 13.4151 14.5 12.0767 14.5C10.7383 14.5 9.65331 13.4151 9.65331 12.0767Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "apart-container__type-text" },
                                [_vm._v(" Акция ")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.properties.with_decoration_furnished ||
                  _vm.properties.with_decoration_finishing ||
                  _vm.properties.with_decoration_design ||
                  _vm.properties.with_decoration_whitebox ||
                  _vm.properties.has_decoration
                    ? [
                        _vm.properties.with_decoration_furnished
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(0)]
                            )
                          : _vm._e(),
                        _vm.properties.with_decoration_finishing
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(1)]
                            )
                          : _vm._e(),
                        _vm.properties.with_decoration_design
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(2)]
                            )
                          : _vm._e(),
                        _vm.properties.has_decoration
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "apart-container__type-tag": true,
                                      "border-gray": !_vm.hideTagText,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "apart-container__type-icon",
                                      attrs: {
                                        src: require("@/assets/images/icons/decoration_dark.svg"),
                                        alt: "",
                                        loading: "lazy",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "apart-container__type-text",
                                      },
                                      [_vm._v(" Отделка включена в стоимость ")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.properties.with_decoration_whitebox
                          ? _c(
                              "div",
                              { staticClass: "apart-container__type-item" },
                              [_vm._m(3)]
                            )
                          : _vm._e(),
                      ]
                    : _vm.properties.with_decoration
                    ? _c("div", { staticClass: "apart-container__type-item" }, [
                        _vm._m(4),
                      ])
                    : _vm._e(),
                  _vm.alternative
                    ? _c("div", { staticClass: "apart-container__type-item" }, [
                        _vm._m(5),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_orange.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text orange" }, [
        _vm._v(" С отделкой и мебелью "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_dark.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text" }, [
        _vm._v(" Чистовая отделка "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_orange.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text orange" }, [
        _vm._v(" Дизайнерская отделка "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_orange.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text orange" }, [
        _vm._v(" Отделка White Box "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/decoration_dark.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("span", { staticClass: "apart-container__type-text" }, [
        _vm._v(" Возможна отделка"),
        _c("br"),
        _vm._v("от 27 000 р. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "apart-container__type-tag" }, [
      _c("img", {
        staticClass: "apart-container__type-icon",
        attrs: {
          src: require("@/assets/images/icons/plans.svg"),
          alt: "",
          loading: "lazy",
        },
      }),
      _c("div", { staticClass: "apart-container__type-text" }, [
        _vm._v(" Возможные планировки "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }